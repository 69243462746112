export const USER_ROLE_LABEL = {
  ENFERMAGEM: {
    label: "Enfermagem",
    color: "pink",
  },
  COZINHA: {
    label: "Cozinha",
    color: "blue",
  },
  HOTELARIA: {
    label: "Hotelaria",
    color: "#86B600",
  },
  LIMPEZA: {
    label: "Limpeza",
    color: "red",
  },
  MANUTENCAO: {
    label: "Manutenção",
    color: "gray",
  },
  ADMIN: {
    label: "Admin",
    color: "gold",
  },
};

export const USER_ROLE = [
  {
    label: "ENFERMAGEM",
    color: "pink",
  },
  {
    label: "COZINHA",
    color: "blue",
  },
  {
    label: "HOTELARIA",
    color: "#86B600",
  },
  {
    label: "LIMPEZA",
    color: "red",
  },
  {
    label: "MANUTENCAO",
    color: "gray",
  },
  {
    label: "ADMIN",
    color: "gold",
  },
];

export const USER_ROLE_LABEL_ALT = {
  ENFERMAGEM: "Enfermagem",
  COZINHA: "Cozinha",
  HOTELARIA: "Hotelaria",
  LIMPEZA: "Limpeza",
  MANUTENCAO: "Manutenção",
  ADMIN: "Administrador",
};
