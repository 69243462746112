import { THEME_SINCRON } from "constants/AuthConstant";
import {
  DIR_LTR,
  NAV_TYPE_SIDE,
  SIDE_NAV_LIGHT,
} from "constants/ThemeConstant";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useTheme = create(
  persist(
    (set) => ({
      navCollapsed: false,
      sideNavTheme: SIDE_NAV_LIGHT,
      locale: "en",
      navType: NAV_TYPE_SIDE,
      topNavColor: "#ED4FA6",
      headerNavColor: "",
      mobileNav: false,
      currentTheme: "light",
      direction: DIR_LTR,
      toggleCollapsedNav: (navCollapsed) => {
        set({ navCollapsed });
      },
      onNavStyleChange: (sideNavTheme) => {
        set({ sideNavTheme });
      },
      onLocaleChange: (locale) => {
        set({ locale });
      },
      onNavTypeChange: (navType) => {
        set({ navType });
      },
      onTopNavColorChange: (topNavColor) => {
        set({ topNavColor });
      },
      onHeaderNavColorChange: (headerNavColor) => {
        set({ headerNavColor });
      },
      onMobileNavToggle: (mobileNav) => {
        set({ mobileNav });
      },
      onSwitchTheme: (currentTheme) => {
        set({ currentTheme });
      },
      onDirectionChange: (direction) => {
        set({ direction });
      },
    }),
    {
      partialize: state => ({
        currentTheme: state.currentTheme,
        locale: state.locale,
      }),
      name: THEME_SINCRON
    }
  )
);
