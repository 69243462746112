import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import { USER_ROLE_LABEL_ALT } from "constants/EmployeeRoles";
import { useAuth } from "stores/auth.store";

export const NavProfile = () => {
  const { logout, user } = useAuth();

  const profileImg = "/img/avatars/default-img.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} style={{ minWidth: 45 }} src={profileImg} />
          <div className="pl-3 d-flex align-flex-start flex-column">
            <h4
              className="mb-0"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "11rem",
              }}
            >
              {user?.name || "User"}
            </h4>
            <span className="text-muted">
              {USER_ROLE_LABEL_ALT[user?.employeeRole]}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={1} onClick={logout}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sair da conta</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          {/* <Badge dot status="success" offset={[-8, 20]} style={{width: 10, height: 10}}> */}
            <Avatar src={profileImg} />
          {/* </Badge> */}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
