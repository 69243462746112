import { notification } from "antd";
import { socket } from "configs/SocketConfig";
import { useEffect } from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useAuth } from "stores/auth.store";
import { useTheme } from "stores/theme.store";
import Views from "./views";



const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const { token, setSocketConnection, userId } = useAuth();
  const { currentTheme } = useTheme();

  useEffect(() => {
    if (token && userId) {
      socket.connect()
      socket.on("connect_response", (data) => {
        if (data) {
          setSocketConnection(true)
          notification.success({
            message: data.response,
          });
        }
      });
      socket.on("connect_error", (err) => {
        console.log(`Erro ao conectar ao socket: ${err}`);
      });
      return () => {
        socket.disconnect();
        setSocketConnection(false)
      };
    }
  }, [socket])

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={
          currentTheme === "dark" ? "dark" : "light"
        }
        insertionPoint="styles-insertion-point"
      >
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
