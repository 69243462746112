import { Switch } from "antd";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useTheme } from "stores/theme.store";

const ListOption = ({ name, selector, disabled, vertical }) => (
  <div
    className={`my-4 ${vertical ? "" : "d-flex align-items-center justify-content-between"
      }`}
  >
    <div
      className={`${disabled ? "opacity-0-3" : ""} ${vertical ? "mb-3" : ""}`}
    >
      {name}
    </div>
    <div>{selector}</div>
  </div>
);

export const ThemeConfigurator = () => {
  const {
    onHeaderNavColorChange,
    onSwitchTheme,
    currentTheme
  } = useTheme();

  const { switcher, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked) => {
    onHeaderNavColorChange("");
    const changedTheme = isChecked ? "dark" : "light";
    onSwitchTheme(changedTheme);
    switcher({ theme: themes[changedTheme] });
  };

  return (
    <>
      <div className="mb-5">
        <ListOption
          name="Modo escuro:"
          selector={
            <Switch checked={currentTheme === "dark"} onChange={toggleTheme} />
          }
        />

      </div>
    </>
  );
};

export default ThemeConfigurator;
