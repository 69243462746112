import { createContextualCan } from "@casl/react";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { defineAbilityFor } from "configs/DefineAbilityConfig";
import useBodyClass from "hooks/useBodyClass";
import AppLocale from "lang";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import { createContext } from "react";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAuth } from "stores/auth.store";
import { useTheme } from "stores/theme.store";

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export const Views = (props) => {
  const { locale, direction } = useTheme();
  const { location } = props;
  const currentAppLocale = AppLocale[locale];

  const { user } = useAuth();
  const ability = defineAbilityFor(user);

  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <AbilityContext.Provider value={ability}>
        <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
          <Switch>
            <Route exact path="/">
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction} />
            </Route>
            <Route path={APP_PREFIX_PATH}>
              <AppLayout direction={direction} location={location} />
            </Route>
          </Switch>
        </ConfigProvider>
      </AbilityContext.Provider>
    </IntlProvider>
  );
};

export default Views;
